import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { Growl } from 'primereact/growl';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { Component } from 'react';
import { MdMic, MdMicOff, MdVideocam, MdVideocamOff } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import { connect, createLocalTracks } from 'twilio-video';
import { OverlaySettingsCall } from '../components/OverlaySettingsCall';
import { AgendaService } from '../service/AgendaService';
import { apiBioAps } from '../service/apiBioAps';
import { apiChat } from '../service/apiChat';
import { apiDevBioAps } from '../service/apiDevBioAps';
import { getUser } from '../service/auth';
import { storageAgendamento, userLogged } from '../utils';

class Consulta extends Component {
  constructor() {
    super();

    this.state = {
      nome: '',
      titulomedico: '',
      medico: '',
      titulopaciente: 'Paciente',
      nomepaciente: '',
      room: '',

      hasPatient: false,

      settingsVisible: true,
      videoInput: '',
      audioInput: '',
      audioOutput: '',

      isAudioActive: true,
      isVideoActive: true,
      audioParticipantActive: true,
      videoParticipantActive: true,
    };

    this.agendaService = new AgendaService();

    this._connect = this._connect.bind(this);
    this._disconnect = this._disconnect.bind(this);
    this._settings = this._settings.bind(this);

    this._onChangeAudioInput = this._onChangeAudioInput.bind(this);
    this._onChangeAudioOutput = this._onChangeAudioOutput.bind(this);
    this._onChangeVideoInput = this._onChangeVideoInput.bind(this);

    this._handleToggleAudio = this._handleToggleAudio.bind(this);
    this.refAudioButton = React.createRef();
    this._handleToggleVideo = this._handleToggleVideo.bind(this);
    this.refVideoButton = React.createRef();

    this._sendWhatsapp = this._sendWhatsapp.bind(this);
    this._PIP = this._PIP.bind(this);
  }

  componentDidMount() {
    let usuario = getUser();
    let nomeusuario = userLogged.getUsername(usuario);
    let room = this.props.match.params.room;

    let newState = this.state;

    apiDevBioAps
      .post('GetAgendamentoBySala', {
        sala_id: room,
      })
      .then((response) => {
        const { nome, medico } = response.data;
        newState.nomepaciente = nome;

        newState.nome = nomeusuario;
        newState.room = room;
        newState.medico = medico;

        this.setState(newState);
      });
  }

  _disconnect() {
    if (this.activeRoom) {
      this.activeRoom.localParticipant.tracks.forEach(function (track) {
        track.track.stop();
        track.track.detach();
      });

      this.activeRoom.disconnect();

      storageAgendamento.remove();

      this.props.history.push({
        pathname: '/',
      });
    }
  }

  _sendWhatsapp() {
    apiBioAps
      .post('sendtelemed', {
        id_sala: this.state.room,
      })
      .then(() => {
        this.growl.show({
          severity: 'success',
          summary: 'Whatsapp',
          detail: 'Menssagem Enviada',
        });
      });
  }

  _connect = () => {
    let token = '';
    var config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'text/plain',
      },
    };

    apiChat
      .post(
        'loginvideo',
        { username: this.state.medico, room: this.state.room },
        config,
      )
      .then((res) => {
        token = res.data.token;

        createLocalTracks({
          video: { deviceId: this.state.videoInput },
          audio: { deviceId: this.state.audioInput },
        }).then((localTracks) => {
          connect(token, { tracks: localTracks }).then(
            (room) => {
              this.activeRoom = room;

              const localVideoTrack = localTracks.find(
                (track) => track.kind === 'video',
              );
              document
                .getElementById('local-media')
                .appendChild(localVideoTrack.attach());

              room.on('participantConnected', (participant) => {
                this.setState({
                  titulopaciente: 'Paciente: ' + participant.identity,
                });

                participant.on('trackSubscribed', (track) => {
                  document
                    .getElementById('remote-media-div')
                    .appendChild(track.attach());

                  this.setState({
                    hasPatient: true,
                  });

                  track.on('disabled', ({ kind }) => {
                    if (kind === 'audio') {
                      this.setState({ audioParticipantActive: false });
                    } else if (kind === 'video') {
                      this.setState({ videoParticipantActive: false });
                    }
                  });

                  track.on('enabled', ({ kind }) => {
                    if (kind === 'audio') {
                      this.setState({ audioParticipantActive: true });
                    } else if (kind === 'video') {
                      this.setState({ videoParticipantActive: true });
                    }
                  });

                  if (track.kind === 'audio') {
                    const audioElement = track.attach();
                    audioElement.setSinkId(this.state.audioOutput).then(() => {
                      document.body.appendChild(audioElement);
                    });
                  }
                });
              });

              room.once('disconnected', (room) => {
                room.localParticipant.tracks.forEach((track) => {
                  track.track.stop();
                  const attachedElements = track.track.detach();
                  attachedElements.forEach((element) => element.remove());
                  room.localParticipant.videoTracks.forEach((video) => {
                    const trackConst = [video][0].track;
                    trackConst.stop();

                    trackConst.detach().forEach((element) => element.remove());

                    room.localParticipant.unpublishTrack(trackConst);
                  });
                });

                this.props.history.push('/agenda');
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              });
            },
            (error) => {
              console.error(`Unable to connect to Room: ${error.message}`);
            },
          );
        });
      });

    this.setState({ nome: '' });
  };

  _settings = () => {
    const newState = this.state;
    newState.settingsVisible = false;

    this.setState(newState);

    this._connect();
  };

  _onChangeAudioInput = (deviceId) => {
    const newState = this.state;
    newState.audioInput = deviceId;

    this.setState(newState);
  };

  _onChangeAudioOutput = (deviceId) => {
    const newState = this.state;
    newState.audioOutput = deviceId;

    this.setState(newState);
  };

  _onChangeVideoInput = (deviceId) => {
    const newState = this.state;
    newState.videoInput = deviceId;

    this.setState(newState);
  };

  _handleToggleVideo = () => {
    const { isVideoActive } = this.state;

    this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
      if (isVideoActive) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });

    this.setState({ isVideoActive: !isVideoActive });
  };

  _handleToggleAudio = () => {
    const { isAudioActive } = this.state;

    this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
      if (isAudioActive) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });

    this.setState({ isAudioActive: !isAudioActive });
  };

  _PIP = async () => {
    const video = document.querySelector('#remote-media-div video');

    console.log(video);
    await video.requestPictureInPicture();
  };

  render() {
    return (
      <>
        <OverlaySettingsCall
          videoInput={this.state.videoInput}
          audioInput={this.state.audioInput}
          audioOutput={this.state.audioOutput}
          onConnect={this._settings}
          onChangeAudioInput={this._onChangeAudioInput}
          onChangeAudioOutput={this._onChangeAudioOutput}
          onChangeVideoInput={this._onChangeVideoInput}
          visible={this.state.settingsVisible}
        />

        <div className='p-grid p-fluid dashboard consulta-doctor'>
          <div className='p-col-12 p-lg-12'>
            <div id='remote-media-div' className='card summarypaciente'>
              <h1>
                {this.state.titulopaciente}: {this.state.nomepaciente}
              </h1>
              {(!this.state.audioParticipantActive ||
                !this.state.videoParticipantActive) && (
                <div className='indicators-devices-mute'>
                  {!this.state.audioParticipantActive && (
                    <MdMicOff className='audio' />
                  )}
                  {!this.state.videoParticipantActive && (
                    <MdVideocamOff className='video' />
                  )}
                </div>
              )}
            </div>
          </div>

          <div className='card summary div_flutuante' id='local-media'></div>

          <div className='botoes'>
            <Growl ref={(el) => (this.growl = el)} />

            <Button label='Finalizar' onClick={this._disconnect} />
            <Button
              disabled={!this.state.hasPatient}
              label='Ativar Janela de Video'
              onClick={this._PIP}
            />
            <button
              title={
                this.state.isAudioActive
                  ? 'Desativar microfone'
                  : 'Ativar microfone'
              }
              ref={this.refAudioButton}
              onClick={this._handleToggleAudio}
              className='icon'>
              {this.state.isAudioActive ? (
                <MdMic size='1.65rem' />
              ) : (
                <MdMicOff size='1.65rem' />
              )}
            </button>
            <button
              title={
                this.state.isVideoActive ? 'Desativar video' : 'Ativar video'
              }
              ref={this.refVideoButton}
              onClick={this._handleToggleVideo}
              className='icon'>
              {this.state.isVideoActive ? (
                <MdVideocam size='1.65rem' />
              ) : (
                <MdVideocamOff size='1.65rem' />
              )}
            </button>
            <Button
              label='Enviar Link Whatsapp'
              style={{ backgroundColor: '#34af23' }}
              onClick={this._sendWhatsapp}
              icon='pi pi-check'
              className='whatsapp'
            />
          </div>

          <OverlayPanel ref={(el) => (this.op = el)}>
            <div className='p-col-12'>
              <div className='card card-w-title'>
                <Editor style={{ height: '220px' }} />
              </div>
            </div>
          </OverlayPanel>
        </div>
      </>
    );
  }
}

export default withRouter(Consulta);
