import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import firebase from '../service/firebase';

export class Agendamento extends Component {
  constructor() {
    super();
    this.state = {
      nome: '',
      dtnascimento: '',
      telefone: '',
      dtagenda: '',
      horario: '',
    };

    this._salvarAgendamento = this._salvarAgendamento.bind(this);
  }

  _salvarAgendamento() {
    var uuid4 = require('uuid4');
    let s = this.state;
    console.log(s);

    if (!s.nome) {
      this.growl.show({ severity: 'error', summary: 'Informe um nome!' });
    } else if (!s.dtnascimento) {
      this.growl.show({
        severity: 'error',
        summary: 'Informe Data de Nascimento!',
      });
    } else if (!s.telefone) {
      this.growl.show({ severity: 'error', summary: 'Informe Telefone!' });
    } else if (!s.dtagenda) {
      this.growl.show({
        severity: 'error',
        summary: 'Informe Data do Agendamento!',
      });
    } else if (!s.horario) {
      this.growl.show({
        severity: 'error',
        summary: 'Informe Horário do Agendamento!',
      });
    } else {
      const formatar = (data) => {
        const ano = data.getFullYear();
        const mes = `00${data.getMonth() + 1}`.slice(-2);
        const dia = `00${data.getDate()}`.slice(-2);

        return `${dia}/${mes}/${ano}`;
      };

      const agendamento = firebase.database().ref('agendamentos');
      var sala_id = uuid4();

      agendamento.push({
        nome: s.nome,
        datanascimento: formatar(new Date(s.dtnascimento)),
        telefone: s.telefone,
        dia: formatar(new Date(s.dtagenda)),
        hora: s.horario,
        status: 'Agendado',
        sala_id: sala_id,
      });

      s.nome = '';
      s.dtnascimento = '';
      s.telefone = '';
      s.dtagenda = '';
      s.horario = '';

      this.setState(s);

      this.growl.show({
        severity: 'success',
        summary: 'Agendamento salvo com sucesso!',
      });
    }
  }

  render() {
    let br = {
      firstDayOfWeek: 1,
      dayNames: [
        'domingo',
        'segunda',
        'terça',
        'quarta',
        'quinta',
        'sexta',
        'sábado',
      ],
      dayNamesShort: ['dom', 'seg', 'ter', 'quar', 'qui', 'sex', 'sáb'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julio',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
      ],
      monthNamesShort: [
        'jan',
        'fev',
        'mar',
        'abr',
        'mai',
        'jun',
        'jul',
        'ago',
        'set',
        'out',
        'nov',
        'dez',
      ],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Semana',
    };

    const horarios = [
      { label: '08:00', value: '08:00' },
      { label: '08:30', value: '08:30' },
      { label: '09:00', value: '09:00' },
      { label: '09:30', value: '09:30' },
      { label: '10:00', value: '10:00' },
      { label: '10:30', value: '10:30' },
      { label: '11:00', value: '11:00' },
      { label: '11:30', value: '11:30' },
      { label: '12:00', value: '12:00' },
      { label: '12:30', value: '12:30' },
      { label: '13:00', value: '13:00' },
      { label: '13:30', value: '13:30' },
      { label: '14:00', value: '14:00' },
      { label: '14:30', value: '14:30' },
      { label: '15:00', value: '15:00' },
      { label: '15:30', value: '15:30' },
      { label: '16:00', value: '16:00' },
      { label: '16:30', value: '16:30' },
      { label: '17:00', value: '17:00' },
      { label: '17:30', value: '17:30' },
    ];
    return (
      <div className='p-grid p-fluid'>
        <div className='p-col-12 p-lg-12'>
          <div className='card card-w-title'>
            <h1>Agendamento</h1>
            <div className='p-grid'>
              <Growl ref={(el) => (this.growl = el)} />
              <div className='p-col-12 p-md-4'>
                <InputText
                  placeholder='Nome'
                  value={this.state.nome}
                  onChange={(e) => this.setState({ nome: e.target.value })}
                />
              </div>
              <div className='p-col-12 p-md-4'>
                <Calendar
                  id='calendardtnascimento'
                  locale={br}
                  dateFormat='dd/mm/yy'
                  placeholder='Data de Nascimento'
                  style={{ width: '300px' }}
                  showIcon={true}
                  value={this.state.dtnascimento}
                  onChange={(e) => this.setState({ dtnascimento: e.value })}
                />
              </div>
              <div className='p-col-12 p-md-4'>
                <InputMask
                  mask='(99)-99999-9999'
                  placeholder='Telefone'
                  value={this.state.telefone}
                  onChange={(e) =>
                    this.setState({ telefone: e.value })
                  }></InputMask>
              </div>
              <div className='p-col-12 p-md-4'>
                <Calendar
                  id='calendardtagendamento'
                  locale={br}
                  dateFormat='dd/mm/yy'
                  placeholder='Data Agenda'
                  style={{ width: '300px' }}
                  showIcon={true}
                  value={this.state.dtagenda}
                  onChange={(e) => this.setState({ dtagenda: e.value })}
                />
              </div>
              <div className='p-col-12 p-md-4'>
                <Dropdown
                  value={this.state.horario}
                  options={horarios}
                  onChange={(e) => {
                    this.setState({ horario: e.value });
                  }}
                  placeholder='Horário'
                />
              </div>
              <div className='p-col-12 p-md-4'></div>
            </div>

            <br></br>
            <br></br>

            <Button label='Salvar' onClick={this._salvarAgendamento} />
          </div>
        </div>
      </div>
    );
  }
}
