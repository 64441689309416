import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Login extends Component {
  render() {
    const logo = 'assets/layout/images/bio.png';
    return (
      <div className='login'>
        <div className='cardmaster'>
          <div className='layout-logo'>
            <img alt='Logo' src={logo} />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Login);
