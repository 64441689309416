import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, createLocalTracks } from 'twilio-video';
import { apiChat } from '../service/apiChat';
import { getUser } from '../service/auth';
import { storageAgendamento } from '../utils/storage';
import { userLogged } from '../utils/userLogged';
import { OverlaySettingsCall } from './OverlaySettingsCall';

class Consulta extends Component {
  constructor() {
    super();

    this.state = {
      nome: '',
      titulomedico: '',
      titulopaciente: 'Paciente',
      room: '',

      settingsVisible: true,
      videoInput: '',
      audioInput: '',
      audioOutput: '',
    };

    this._connect = this._connect.bind(this);
    this._disconnect = this._disconnect.bind(this);
    this._settings = this._settings.bind(this);

    this._onChangeAudioInput = this._onChangeAudioInput.bind(this);
    this._onChangeAudioOutput = this._onChangeAudioOutput.bind(this);
    this._onChangeVideoInput = this._onChangeVideoInput.bind(this);
  }

  componentDidMount() {
    let usuario = getUser();
    let nomeusuario = userLogged.getUsername(usuario);

    let sala = '';
    let nome = '';

    if (!!this.props.location.agendamento) {
      sala = this.props.location.agendamento.sala_id;
      nome = this.props.location.agendamento.nome;
      storageAgendamento.save(this.props.location.agendamento);
    } else {
      const agendamento = storageAgendamento.get();
      sala = agendamento.sala_id;
      nome = agendamento.nome;
    }

    let newState = this.state;
    newState.titulopaciente = nome;
    newState.nome = nomeusuario;
    newState.room = sala;

    this.setState(newState);
  }

  _disconnect() {
    if (this.activeRoom) {
      this.activeRoom.localParticipant.tracks.forEach(function (track) {
        track.track.stop();
        track.track.detach();
      });

      this.activeRoom.disconnect();

      storageAgendamento.remove();
    }
  }

  _connect = () => {
    let token = '';
    var config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'text/plain',
      },
    };

    apiChat
      .post(
        'loginvideo',
        { username: this.state.nome, room: this.state.room },
        config,
      )
      .then((res) => {
        token = res.data.token;

        createLocalTracks({
          video: { deviceId: this.state.videoInput },
          audio: { deviceId: this.state.audioInput },
        }).then((localTracks) => {
          connect(token, { tracks: localTracks }).then(
            (room) => {
              this.activeRoom = room;

              const localVideoTrack = localTracks.find(
                (track) => track.kind === 'video',
              );
              document
                .getElementById('local-media')
                .appendChild(localVideoTrack.attach());

              room.on('participantConnected', (participant) => {
                this.setState({
                  titulopaciente: 'Paciente: ' + participant.identity,
                });

                participant.on('trackSubscribed', (track) => {
                  document
                    .getElementById('remote-media-div')
                    .appendChild(track.attach());

                  if (track.kind === 'audio') {
                    const audioElement = track.attach();
                    audioElement.setSinkId(this.state.audioOutput).then(() => {
                      document.body.appendChild(audioElement);
                    });
                  }
                });
              });

              room.once('disconnected', (room) => {
                room.localParticipant.tracks.forEach((track) => {
                  track.track.stop();
                  const attachedElements = track.track.detach();
                  attachedElements.forEach((element) => element.remove());
                  room.localParticipant.videoTracks.forEach((video) => {
                    const trackConst = [video][0].track;
                    trackConst.stop();

                    trackConst.detach().forEach((element) => element.remove());

                    room.localParticipant.unpublishTrack(trackConst);
                  });
                });

                this.props.history.push('/agenda');
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              });
            },
            (error) => {
              console.error(`Unable to connect to Room: ${error.message}`);
            },
          );
        });
      });

    this.setState({ nome: '' });
  };

  _settings = () => {
    const newState = this.state;
    newState.settingsVisible = false;

    this.setState(newState);

    this._connect();
  };

  _onChangeAudioInput = (deviceId) => {
    const newState = this.state;
    newState.audioInput = deviceId;

    this.setState(newState);
  };

  _onChangeAudioOutput = (deviceId) => {
    const newState = this.state;
    newState.audioOutput = deviceId;

    this.setState(newState);
  };

  _onChangeVideoInput = (deviceId) => {
    const newState = this.state;
    newState.videoInput = deviceId;

    this.setState(newState);
  };

  render() {
    return (
      <>
        <OverlaySettingsCall
          videoInput={this.state.videoInput}
          audioInput={this.state.audioInput}
          audioOutput={this.state.audioOutput}
          onConnect={this._settings}
          onChangeAudioInput={this._onChangeAudioInput}
          onChangeAudioOutput={this._onChangeAudioOutput}
          onChangeVideoInput={this._onChangeVideoInput}
          visible={this.state.settingsVisible}
        />

        <div className='p-grid p-fluid dashboard consulta'>
          <div className='p-col-12 p-lg-12'>
            <div
              id='remote-media-div'
              className='card summarypaciente'
              style={{ height: '90vh', width: '100%' }}>
              <h1>{this.state.titulopaciente}</h1>
            </div>
          </div>

          <div className='card summary div_flutuante' id='local-media'></div>

          <div className='botoes_flutuante'>
            <Button label='Finalizar' onClick={this._disconnect} />
          </div>

          <OverlayPanel ref={(el) => (this.op = el)}>
            <div className='p-col-12'>
              <div className='card card-w-title'>
                <Editor style={{ height: '220px' }} />
              </div>
            </div>
          </OverlayPanel>
        </div>
      </>
    );
  }
}

export default withRouter(Consulta);
