import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';

export function OverlaySettingsCall({
  onConnect,
  videoInput,
  audioInput,
  audioOutput,
  onChangeVideoInput,
  onChangeAudioInput,
  onChangeAudioOutput,
  visible = false,
}) {
  const [listAudioInput, setListAudioInput] = useState([]);
  const [listAudioOutput, setListAudioOutput] = useState([]);
  const [listVideoInput, setListVideoInput] = useState([]);

  const videoRef = useRef();

  useEffect(() => {
    async function onGetListDevices() {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const listInputAudio = [];
        const listOutputAudio = [];
        const listInputVideo = [];

        devices.forEach((device) => {
          switch (device.kind) {
            case 'audiooutput':
              listOutputAudio.push({
                value: device.deviceId,
                label: device.label,
              });
              break;
            case 'audioinput':
              listInputAudio.push({
                value: device.deviceId,
                label: device.label,
              });
              break;
            case 'videoinput':
              listInputVideo.push({
                value: device.deviceId,
                label: device.label,
              });
              break;
            default:
              break;
          }
        });

        setListAudioInput([...listInputAudio]);
        if (listInputAudio[0]) onChangeAudioInput(listInputAudio[0]?.value);
        setListAudioOutput([...listOutputAudio]);
        if (listOutputAudio[0]) onChangeAudioOutput(listOutputAudio[0]?.value);
        setListVideoInput([...listInputVideo]);
        if (listInputVideo[0]) onChangeVideoInput(listInputVideo[0]?.value);
      });
    }

    async function requestMediaDevice() {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .then(onGetListDevices)
        .catch((err) => {
          console.error(err);
          console.log(err);
        });
    }

    requestMediaDevice();
  }, [
    listAudioInput,
    onChangeAudioInput,
    onChangeAudioOutput,
    onChangeVideoInput,
  ]);

  useEffect(() => {
    async function onRenderVideoInputDevice() {
      if (videoInput === '') return;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: {
              exact: videoInput,
            },
          },
          audio: true,
        });

        if (stream.getAudioTracks()[0].enabled)
          stream.getAudioTracks()[0].enabled = false;

        videoRef.current.srcObject = stream;
      } catch (err) {
        console.error(err);
      }
    }

    onRenderVideoInputDevice();
  }, [videoInput]);

  function handleInitConnect() {
    if (!videoInput) return window.alert('Selecione um dispositivo de vídeo');

    if (!audioInput)
      return window.alert('Selecione um dispositivo de entrada de áudio');

    if (!audioOutput && listAudioOutput.length > 0)
      return window.alert('Selecione um dispositivo de saída de áudio');

    onConnect();
  }

  if (!visible) return <></>;

  return (
    <div className='overlaySettingsCall'>
      <div className='containerSettingsCall'>
        <p>Configuração dos dispositivos</p>
        <div className='contentSettingsCall'>
          <div className='optionsSettings'>
            <div>
              <p>Selecione o vídeo</p>
              <Dropdown
                value={videoInput}
                options={listVideoInput}
                placeholder='Escolha o vídeo'
                onChange={(event) => onChangeVideoInput(event.value)}
              />
            </div>
            <div>
              <p>Selecione a entrada de áudio</p>
              <Dropdown
                value={audioInput}
                options={listAudioInput}
                placeholder='Escolha o audio de entrada'
                onChange={(event) => onChangeAudioInput(event.value)}
              />
            </div>
            {!!listAudioOutput.length > 0 && (
              <div>
                <p>Selecione a saída de áudio</p>
                <Dropdown
                  value={audioOutput}
                  options={listAudioOutput}
                  placeholder='Escolha o audio de saida'
                  onChange={(event) => onChangeAudioOutput(event.value)}
                />
              </div>
            )}
          </div>
          <video ref={videoRef} autoPlay></video>
        </div>

        <div className='footerSettingsCall'>
          <Button label='Ir para consulta' onClick={handleInitConnect} />
        </div>
      </div>
    </div>
  );
}
