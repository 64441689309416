import axios from 'axios';
import { apiChat } from './apiChat';

export class AgendaService {
  async getAgendas() {
    const res = await axios.get('assets/data/agendamentos.json');
    return res.data.data;
  }

  sendWhatsApp(nome, link, numero) {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded';

    const param = { name: nome, link: link, numero: numero };

    console.log(param);

    apiChat.post('sendwhatstemplate', param);

    return;
  }
}
