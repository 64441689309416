import axios from 'axios';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import { createLocalVideoTrack } from 'twilio-video';

export default class HomeClient extends Component {
  constructor() {
    super();
    this.state = {
      nome: '',
      titulomedico: 'Medico',
      titulopaciente: 'Paciente',
    };
    this._conectar = this._conectar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.trackSubscribed = this.trackSubscribed.bind(this);
    this.trackUnsubscribed = this.trackUnsubscribed.bind(this);
    this.participantConnected = this.participantConnected.bind(this);
  }

  trackSubscribed(div, track) {
    div.appendChild(track.attach());
  }

  trackUnsubscribed(track) {
    track.detach().forEach((element) => element.remove());
  }

  participantConnected(participant) {
    const div = document.createElement('div');
    div.id = participant.sid;
    div.innerText = participant.identity;

    participant.on('trackSubscribed', (track) =>
      this.trackSubscribed(div, track),
    );
    participant.on('trackUnsubscribed', this.trackUnsubscribed);

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        this.trackSubscribed(div, publication.track);
      }
    });

    document.body.appendChild(div);
  }

  _conectar = () => {
    const { connect } = require('twilio-video');

    let token = '';

    this.setState({ titulopaciente: 'Paciente: ' + this.state.nome });

    axios
      .post('http://0.0.0.0:8090/api/loginvideo', { username: this.state.nome })
      .then((res) => {
        token = res.data.token;

        connect(token).then(
          (room) => {
            createLocalVideoTrack().then((track) => {
              const localMediaContainer = document.getElementById(
                'local-media',
              );
              localMediaContainer.appendChild(track.attach());
            });

            room.participants.forEach((participant) => {
              participant.tracks.forEach((publication) => {
                this.setState({
                  titulomedico: 'Dr(a): ' + participant.identity,
                });
                if (publication.track) {
                  const track = publication.track;
                  document
                    .getElementById('remote-media-div')
                    .appendChild(track.attach());
                }
              });

              participant.on('trackSubscribed', (track) => {
                document
                  .getElementById('remote-media-div')
                  .appendChild(track.attach());
              });
            });
          },
          (error) => {
            console.error(`Unable to connect to Room: ${error.message}`);
          },
        );
      });

    this.setState({ nome: '' });
  };

  handleChange(event) {
    this.setState({ nome: event.target.value });
  }

  render() {
    return (
      <div className='p-grid p-fluid dashboard'>
        <div className='p-col-12 p-lg-10'>
          <InputText
            placeholder='Nome do Paciente'
            value={this.state.nome}
            onChange={this.handleChange}
          />
          <Button onClick={this._conectar} label={'Conectar'}></Button>

          <div
            id='remote-media-div'
            className='card summarymedico'
            style={{ height: '80vh', width: '100%' }}>
            <h1>{this.state.titulomedico}</h1>
          </div>
        </div>

        <div className='p-col-12 p-lg-2'>
          <div className='card summary' id='local-media'>
            <h1>{this.state.titulopaciente}</h1>
          </div>
        </div>

        <div className='container'>
          <div className='row'></div>
          <div className='row'></div>
          <div className='row'>
            <div className='col-4'></div>
          </div>
        </div>
      </div>
    );
  }
}
