import classNames from 'classnames';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getUser, logout } from '../service/auth';

class AppProfile extends Component {
  constructor() {
    super();
    this.state = {
      expanded: false,
      usuario: '',
    };
    this.onClick = this.onClick.bind(this);
    this._sair = this._sair.bind(this);
  }

  onClick(event) {
    this.setState({ expanded: !this.state.expanded });
    event.preventDefault();
  }

  _sair = () => {
    logout();
    this.props.history.push('/');
  };

  componentDidMount() {
    let usuario = getUser();

    if (usuario === 'joao.souza') {
      this.setState({ usuario: 'João de Souza' });
    } else if (usuario === 'jose.silva') {
      this.setState({ usuario: 'José da Silva' });
    } else if (usuario === 'rodrigo.faccioli') {
      this.setState({ usuario: 'Rodrigo Faccioli' });
    } else if (usuario === 'bruno.martins') {
      this.setState({ usuario: 'Bruno Martins' });
    } else if (usuario === 'douglas.vieira') {
      this.setState({ usuario: 'Douglas Vieira' });
    } else if (usuario === 'carlos.braga') {
      this.setState({ usuario: 'Carlos Braga' });
    } else if (usuario === 'graciana.covas') {
      this.setState({ usuario: 'Graciana Covas' });
    }
  }

  render() {
    return (
      <div className='layout-profile'>
        <button className='p-link layout-profile-link' onClick={this.onClick}>
          <span className='username'>{this.state.usuario}</span>
          <i className='pi pi-fw pi-cog' />
        </button>
        <ul
          className={classNames({
            'layout-profile-expanded': this.state.expanded,
          })}>
          <li>
            <button onClick={this._sair} className='p-link'>
              <i className='pi pi-fw pi-power-off' />
              <span>Sair</span>
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(AppProfile);
