import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import classNames from 'classnames';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Agenda from '../components/Agenda';
import { Agendamento } from '../components/Agendamento';
import { AppFooter } from '../components/AppFooter';
import { AppMenu } from '../components/AppMenu';
import AppProfile from '../components/AppProfile';
import { AppTopbar } from '../components/AppTopbar';
import Consulta from '../components/Consulta';
import Home from '../components/Home';
import HomeClient from '../components/HomeClient';
import '../layout/layout.scss';
import { inIframe } from '../utils/validation';

export default class Principal extends Component {
  constructor() {
    super();
    this.state = {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.createMenu();
  }

  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === 'overlay') {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === 'static') {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick() {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  createMenu() {
    this.menu = [
      { label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/home' },
      { label: 'Agendamento', icon: 'pi pi-calendar-plus', to: '/agendamento' },
      { label: 'Consulta', icon: 'pi pi-desktop', to: '/agenda' },
    ];
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
          'gi',
        ),
        ' ',
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
  }

  render() {
    const logo =
      this.state.layoutColorMode === 'dark'
        ? 'assets/layout/images/bio.png'
        : 'assets/layout/images/bio.png';

    const wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-static-sidebar-inactive':
        this.state.staticMenuInactive && this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active':
        this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive,
    });

    const sidebarClassName = classNames('layout-sidebar', {
      'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
      'layout-sidebar-light': this.state.layoutColorMode === 'light',
    });

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        {!inIframe() && (
          <>
            <AppTopbar onToggleMenu={this.onToggleMenu} />
            <div
              ref={(el) => (this.sidebar = el)}
              className={sidebarClassName}
              onClick={this.onSidebarClick}>
              <div className='layout-logo'>
                <img style={{ width: '150px' }} alt='Logo' src={logo} />
              </div>

              <AppProfile />
              <AppMenu
                model={this.menu}
                onMenuItemClick={this.onMenuItemClick}
              />
            </div>
          </>
        )}

        <div className={`layout-main ${inIframe() && 'in-iframe'}`}>
          <Route path='/client' exact component={HomeClient} />
          <Route path='/' exact component={Home} />
          <Route path='/consulta' component={Consulta} />
          <Route path='/agenda' component={Agenda} />
          <Route path='/agendamento' component={Agendamento} />
        </div>

        <AppFooter />

        <div className='layout-mask'></div>
      </div>
    );
  }
}
