import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBtBjpTGbRB8uaWWLS7OYVPpOKqMGjFOs4',
  authDomain: 'telemed4ubioaps.firebaseapp.com',
  databaseURL: 'https://telemed4ubioaps-default-rtdb.firebaseio.com',
  projectId: 'telemed4ubioaps',
  storageBucket: 'telemed4ubioaps.appspot.com',
  messagingSenderId: '514652088555',
  appId: '1:514652088555:web:c7a790a7fc51ecf27776ae',
  measurementId: 'G-VK1644SQ86',
};

firebase.initializeApp(config);
export default firebase;
