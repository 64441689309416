import React, { Component } from 'react';

export default class FinalClient extends Component {
  render() {
    const logo = 'assets/layout/images/bio.png';
    return (
      <div className='obrigado'>
        <p>Obrigado por utilizar nosso serviço!</p>
        <div className='cardmaster'>
          <div className='layout-logo'>
            <img alt='Logo' src={logo} />
          </div>
        </div>
        <span />
      </div>
    );
  }
}
