import React, { Component } from 'react';
import { MdMic, MdMicOff, MdVideocam, MdVideocamOff } from 'react-icons/md';
import { createLocalTracks } from 'twilio-video';
import { OverlaySettingsCall } from '../components/OverlaySettingsCall';
import { apiChat } from '../service/apiChat';
import { apiDevBioAps } from '../service/apiDevBioAps';

export default class ConsultaClient extends Component {
  constructor() {
    super();
    this.state = {
      nome: '',
      titulomedico: 'Medico',
      titulopaciente: 'Paciente',
      nomemedico: '',
      room: '',

      settingsVisible: true,
      videoInput: '',
      audioInput: '',
      audioOutput: '',

      isAudioActive: true,
      isVideoActive: true,
      audioParticipantActive: true,
      videoParticipantActive: true,
    };

    this._connect = this._connect.bind(this);
    this.trackSubscribed = this.trackSubscribed.bind(this);
    this.trackUnsubscribed = this.trackUnsubscribed.bind(this);
    this.participantConnected = this.participantConnected.bind(this);

    this._settings = this._settings.bind(this);

    this._onChangeAudioInput = this._onChangeAudioInput.bind(this);
    this._onChangeAudioOutput = this._onChangeAudioOutput.bind(this);
    this._onChangeVideoInput = this._onChangeVideoInput.bind(this);

    this._handleToggleAudio = this._handleToggleAudio.bind(this);
    this.refAudioButton = React.createRef();
    this._handleToggleVideo = this._handleToggleVideo.bind(this);
    this.refVideoButton = React.createRef();
  }

  trackSubscribed(div, track) {
    div.appendChild(track.attach());
  }

  trackUnsubscribed(track) {
    track.detach().forEach((element) => element.remove());
  }

  participantConnected(participant) {
    const div = document.createElement('div');
    div.id = participant.sid;
    div.innerText = participant.identity;

    participant.on('trackSubscribed', (track) =>
      this.trackSubscribed(div, track),
    );
    participant.on('trackUnsubscribed', this.trackUnsubscribed);

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        this.trackSubscribed(div, publication.track);
      }
    });

    document.body.appendChild(div);
  }

  componentDidMount() {
    let room = this.props.match.params.room;

    let newState = this.state;

    apiDevBioAps
      .post('GetAgendamentoBySala', {
        sala_id: room,
      })
      .then((response) => {
        const { medico, nome } = response.data;

        newState.nome = nome;
        newState.room = room;
        newState.nomemedico = medico;

        this.setState(newState);
      });
  }

  _settings = () => {
    const newState = this.state;
    newState.settingsVisible = false;

    this.setState(newState);

    this._connect();
  };

  _onChangeAudioInput = (deviceId) => {
    const newState = this.state;
    newState.audioInput = deviceId;

    this.setState(newState);
  };

  _onChangeAudioOutput = (deviceId) => {
    const newState = this.state;
    newState.audioOutput = deviceId;

    this.setState(newState);
  };

  _onChangeVideoInput = (deviceId) => {
    const newState = this.state;
    newState.videoInput = deviceId;

    this.setState(newState);
  };

  _connect = () => {
    const { connect } = require('twilio-video');

    let token = '';

    apiChat
      .post('loginvideo', {
        username: this.state.nome,
        room: this.state.room,
      })
      .then((res) => {
        token = res.data.token;

        createLocalTracks({
          video: { deviceId: this.state.videoInput },
          audio: { deviceId: this.state.audioInput },
        }).then((localTracks) => {
          connect(token, { tracks: localTracks }).then(
            (room) => {
              this.activeRoom = room;

              const localVideoTrack = localTracks.find(
                (track) => track.kind === 'video',
              );
              document
                .getElementById('local-media')
                .appendChild(localVideoTrack.attach());

              room.participants.forEach((participant) => {
                participant.tracks.forEach((publication) => {
                  if (publication.track) {
                    const { track } = publication;

                    document
                      .getElementById('remote-media-div')
                      .appendChild(track.attach());
                  }
                });

                participant.on('trackSubscribed', (track) => {
                  document
                    .getElementById('remote-media-div')
                    .appendChild(track.attach());

                  track.on('disabled', ({ kind }) => {
                    if (kind === 'audio') {
                      this.setState({ audioParticipantActive: false });
                    } else if (kind === 'video') {
                      this.setState({ videoParticipantActive: false });
                    }
                  });

                  track.on('enabled', ({ kind }) => {
                    if (kind === 'audio') {
                      this.setState({ audioParticipantActive: true });
                    } else if (kind === 'video') {
                      this.setState({ videoParticipantActive: true });
                    }
                  });
                });

                participant.on('disconnected', (room) => {
                  this.props.history.push({
                    pathname: '/obrigado',
                    sala: this.state.room,
                  });
                });
              });
            },
            (error) => {
              console.error(`Unable to connect to Room: ${error.message}`);
            },
          );
        });
      });

    this.setState({ nome: '' });
  };

  _handleToggleVideo = () => {
    const { isVideoActive } = this.state;

    this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
      if (isVideoActive) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });

    this.setState({ isVideoActive: !isVideoActive });
  };

  _handleToggleAudio = () => {
    const { isAudioActive } = this.state;

    this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
      if (isAudioActive) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });

    this.setState({ isAudioActive: !isAudioActive });
  };

  render() {
    return (
      <>
        <OverlaySettingsCall
          videoInput={this.state.videoInput}
          audioInput={this.state.audioInput}
          audioOutput={this.state.audioOutput}
          onConnect={this._settings}
          onChangeAudioInput={this._onChangeAudioInput}
          onChangeAudioOutput={this._onChangeAudioOutput}
          onChangeVideoInput={this._onChangeVideoInput}
          visible={this.state.settingsVisible}
        />
        <div className='p-grid p-fluid dashboard consultaclient'>
          <div className='p-col-12 p-lg-12'>
            <div id='remote-media-div' className='card summarymedico'>
              <h1>
                {this.state.titulomedico}: {this.state.nomemedico}
              </h1>
              {(!this.state.audioParticipantActive ||
                !this.state.videoParticipantActive) && (
                <div className='indicators-devices-mute'>
                  {!this.state.audioParticipantActive && (
                    <MdMicOff className='audio' />
                  )}
                  {!this.state.videoParticipantActive && (
                    <MdVideocamOff className='video' />
                  )}
                </div>
              )}
            </div>
          </div>

          <div className='card summary div_flutuante' id='local-media' />

          <div className='botoes'>
            <button
              title={
                this.state.isAudioActive
                  ? 'Desativar microfone'
                  : 'Ativar microfone'
              }
              ref={this.refAudioButton}
              onClick={this._handleToggleAudio}
              className='icon'>
              {this.state.isAudioActive ? (
                <MdMic size='1.65rem' />
              ) : (
                <MdMicOff size='1.65rem' />
              )}
            </button>
            <button
              title={
                this.state.isVideoActive ? 'Desativar video' : 'Ativar video'
              }
              ref={this.refVideoButton}
              onClick={this._handleToggleVideo}
              className='icon'>
              {this.state.isVideoActive ? (
                <MdVideocam size='1.65rem' />
              ) : (
                <MdVideocamOff size='1.65rem' />
              )}
            </button>
          </div>
        </div>
      </>
    );
  }
}
