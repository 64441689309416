import axios from 'axios';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import { createLocalVideoTrack } from 'twilio-video';

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      nome: '',
      titulomedico: '',
      titulopaciente: 'Paciente',
    };
    this._conectar = this._conectar.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  _conectar = () => {
    const { connect } = require('twilio-video');

    let token = '';

    this.setState({ titulomedico: 'Dr(a) ' + this.state.nome });

    axios
      .post('http://0.0.0.0:8090/api/loginvideo', { username: this.state.nome })
      .then((res) => {
        token = res.data.token;

        connect(token).then(
          (room) => {
            createLocalVideoTrack().then((track) => {
              const localMediaContainer = document.getElementById(
                'local-media',
              );
              localMediaContainer.appendChild(track.attach());
            });

            room.on('participantConnected', (participant) => {
              this.setState({
                titulopaciente: 'Paciente: ' + participant.identity,
              });

              participant.on('trackSubscribed', (track) => {
                document
                  .getElementById('remote-media-div')
                  .appendChild(track.attach());
              });
            });
          },
          (error) => {
            console.error(`Unable to connect to Room: ${error.message}`);
          },
        );
      });

    this.setState({ nome: '' });
  };

  handleChange(event) {
    this.setState({ nome: event.target.value });
  }

  render() {
    return (
      <div className='p-grid p-fluid dashboard'>
        <div className='p-col-12 p-lg-10'>
          <div
            id='remote-media-div'
            className='card summarypaciente'
            style={{ height: '60vh', width: '100%' }}>
            <h1>{this.state.titulopaciente}</h1>
          </div>
        </div>

        <div className='p-col-12 p-lg-2'>
          <InputText
            placeholder='Nome do Médico'
            value={this.state.nome}
            onChange={this.handleChange}
          />
          <Button onClick={this._conectar} label={'Conectar'}></Button>
          <div className='card summary' id='local-media'>
            <h1>{this.state.titulomedico}</h1>
          </div>
        </div>

        <div className='p-col-12'>
          <div className='card card-w-title'>
            <Editor style={{ height: '220px' }} />
          </div>
        </div>

        <div className='container'>
          <div className='row'></div>
          <div className='row'></div>
          <div className='row'>
            <div className='col-4'></div>
          </div>
        </div>
      </div>
    );
  }
}
