export const userLogged = {
  getUsername(username) {
    switch (username) {
      case 'joao.souza':
        return 'João de Souza';

      case 'jose.silva':
        return 'José da Silva';

      case 'rodrigo.faccioli':
        return 'Rodrigo Faccioli';

      case 'bruno.martins':
        return 'Bruno Martins';

      case 'douglas.vieira':
        return 'Douglas Vieira';

      case 'carlos.braga':
        return 'Carlos Braga';

      case 'graciana.covas':
        return 'Graciana Covas';

      default:
        return '';
    }
  },
};
