import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { AgendaService } from '../service/AgendaService';
import firebase from '../service/firebase';

class Agenda extends Component {
  constructor() {
    super();
    this.state = {
      dataTableValue: [],
      dataViewValue: [],
      picklistSourceCars: [],
      picklistTargetCars: [],
      orderlistCars: [],
      treeData1: [],
      treeData2: [],
      selectedFile: null,
      selectedFiles: null,
      documents: [],
      date1: null,

      nomepaciente: '',
      dia: '',
      horario: '',
      link: '',
      telefone: '',
    };

    this.agendaService = new AgendaService();

    this.onSortChange = this.onSortChange.bind(this);
    this._selecionar = this._selecionar.bind(this);
    this._iniciarConsulta = this._iniciarConsulta.bind(this);
    this._sendWhatsapp = this._sendWhatsapp.bind(this);
    this._carregaDados = this._carregaDados.bind(this);
    this._carregaData = this._carregaData.bind(this);
  }

  componentDidMount() {
    let s = this.state;
    s.date1 = new Date();

    this._carregaDados();

    //this.agendaService.getAgendas().then(data => this.setState({dataTableValue: data}));
  }

  _carregaDados() {
    const agendamento = firebase.database().ref('agendamentos');
    let s = this.state;

    const formatar = (data) => {
      const ano = data.getFullYear();
      const mes = `00${data.getMonth() + 1}`.slice(-2);
      const dia = `00${data.getDate()}`.slice(-2);

      return `${dia}/${mes}/${ano}`;
    };

    var dataselecionada = formatar(new Date(s.date1));

    agendamento.on('value', (snapshot) => {
      let ag = snapshot.val();
      let agData = [];

      for (let a in ag) {
        if (ag[a].dia === dataselecionada) {
          agData.push(ag[a]);
        }
      }

      this.setState({ dataTableValue: agData });
    });
  }

  _carregaData(e) {
    let s = this.state;
    s.date1 = e.value;

    this.setState(s);

    this._carregaDados();
  }

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf('!') === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  _selecionar(event) {
    let nome = event.value.nome;
    let dia = event.value.dia;
    let horario = event.value.hora;
    let link =
      'https://dev-telemed.biodoctor.app/#/client/' + event.value.sala_id;
    let telefone = event.value.telefone;

    this.setState({
      nomepaciente: nome,
      dia: dia,
      horario: horario,
      link: link,
      telefone: telefone,
    });

    this.overlayPanel.toggle(event);

    this.setState({ dataTableSelection: event.value });
  }

  _iniciarConsulta() {
    this.props.history.push({
      pathname: '/consulta',
      agendamento: this.state.dataTableSelection,
    });
  }

  _sendWhatsapp() {
    let s = this.state;

    this.agendaService.sendWhatsApp(s.nomepaciente, s.link, s.telefone);

    this.growl.show({
      severity: 'success',
      summary: 'Whatsapp',
      detail: 'Menssagem Enviada',
    });
  }

  render() {
    let br = {
      firstDayOfWeek: 1,
      dayNames: [
        'domingo',
        'segunda',
        'terça',
        'quarta',
        'quinta',
        'sexta',
        'sábado',
      ],
      dayNamesShort: ['dom', 'seg', 'ter', 'quar', 'qui', 'sex', 'sáb'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julio',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
      ],
      monthNamesShort: [
        'jan',
        'fev',
        'mar',
        'abr',
        'mai',
        'jun',
        'jul',
        'ago',
        'set',
        'out',
        'nov',
        'dez',
      ],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Semana',
    };

    return (
      <div className='p-grid  agendamento'>
        <div className='card card-w-title p-col-12 agenda'>
          <h1>Agenda</h1>

          <Calendar
            locale={br}
            dateFormat='dd/mm/yy'
            placeholder='Data Agenda'
            style={{ width: '300px' }}
            showIcon={true}
            value={this.state.date1}
            onChange={(e) => this._carregaData(e)}
          />

          <DataTable
            value={this.state.dataTableValue}
            paginatorPosition='both'
            selectionMode='single'
            paginator={true}
            rows={10}
            responsive={true}
            selection={this.state.dataTableSelection}
            onSelectionChange={(event) => this._selecionar(event)}>
            <Column field='hora' header='Hora' sortable={true} />
            <Column field='nome' header='Nome do Paciente' sortable={true} />
            <Column
              field='datanascimento'
              header='Data Nascimento'
              sortable={true}
            />
            <Column field='status' header='Status' sortable={true} />
          </DataTable>
        </div>

        <OverlayPanel
          className='ageOverlayPanel'
          ref={(el) => (this.overlayPanel = el)}
          showCloseIcon={true}
          my='center'
          at='center'
          dismissable={false}>
          <div className='p-col-12'>
            <div className='card card-w-title'>
              <h1>{this.state.nomepaciente}</h1>
            </div>
            <div className='gridDetalhes'>
              <h2>Dia da Consulta</h2>
              <h1>{this.state.dia}</h1>

              <h2>Hora da Consulta</h2>
              <h1>{this.state.horario}</h1>

              <h2>Link da Consulta</h2>
              <a href={this.state.link}>{this.state.link}</a>
            </div>

            <div className='gridBotoes'>
              <Growl ref={(el) => (this.growl = el)} />
              <Button
                label='Enviar Link Whatsapp'
                style={{ backgroundColor: '#34af23' }}
                onClick={this._sendWhatsapp}
                icon='pi pi-check'
              />
              <Button
                label='Iniciar Consulta'
                onClick={this._iniciarConsulta}
                icon='pi pi-play'
              />
            </div>
          </div>
        </OverlayPanel>
      </div>
    );
  }
}

export default withRouter(Agenda);
