export const storageAgendamento = {
  key: '@TLM:Agendamento',

  get: () => {
    return JSON.parse(localStorage.getItem(storageAgendamento.key));
  },

  save: (agendamento) => {
    localStorage.setItem(storageAgendamento.key, JSON.stringify(agendamento));
  },

  remove: () => {
    localStorage.removeItem(storageAgendamento.key);
  },
};
