import React, { Component } from 'react';
import { Rating } from 'primereact/rating';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

export default class AvaliacaoClient extends Component {
  constructor() {
    super();
    this.state = {
      sala: '',
      nota: '',
      sugestao: '',
    };

    this._enviar = this._enviar.bind(this);
  }

  componentDidMount() {
    const state = this.props.location.sala;
    this.setState({ sala: state });
  }

  _enviar() {
    setTimeout(() => {
      window.location.reload();
    }, 1000);

    this.props.history.push({
      pathname: '/obrigado',
    });
  }

  render() {
    const logo = 'assets/layout/images/logo_telemed.png';
    return (
      <div className='p-grid'>
        <div className='p-col-12'>
          <div
            className='card'
            style={{
              alignItems: 'center',
              alignContent: 'center',
              textAlign: 'center',
            }}>
            <div className='layout-logo'>
              <img alt='Logo' src={logo} style={{ width: '250px' }} />
            </div>
            <br></br>
            <h1>O que você achou do nosso atendimento?</h1>
            <Rating
              value={this.state.nota}
              onChange={(e) => this.setState({ nota: e.value })}
            />

            <br></br>
            <h1>Nos deixe alguma crítica, reclamação ou sugestão.</h1>
            <InputTextarea
              rows={5}
              cols={50}
              value={this.state.sugestao}
              onChange={(e) => this.setState({ sugestao: e.target.value })}
              autoResize={true}
            />
            <br></br>
            <br></br>
            <Button label='Enviar' onClick={this._enviar} />
          </div>
        </div>
      </div>
    );
  }
}
