import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import {
  AvaliacaoClient,
  ConsultaClient,
  ThanksClient,
  Principal,
  Login,
} from '../pages';
import ConsultDoctor from '../pages/ConsultDoctor';
import { isAuthenticated } from '../service/auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <HashRouter>
    <Switch>
      <Route exact path='/' component={Login} />
      <PrivateRoute path='/home' component={Principal} />
      <PrivateRoute path='/agenda' component={Principal} />
      <PrivateRoute path='/consulta' component={Principal} />
      <PrivateRoute path='/agendamento' component={Principal} />
      <Route path='/doctor/:room' component={ConsultDoctor} />
      <Route path='/client/:room' component={ConsultaClient} />
      <Route path='/avaliacao' component={AvaliacaoClient} />
      <Route path='/obrigado' component={ThanksClient} />
      <Route path='/signup' component={() => <h1>SignUp</h1>} />
      <PrivateRoute path='/app' component={() => <h1>App</h1>} />
      <Route path='*' component={() => <h1>Page not found</h1>} />
    </Switch>
  </HashRouter>
);

export default Routes;
