import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import React from 'react';
import { HashRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import './layout/layout.scss';
import Routes from './routes';

function App() {
  return (
    <HashRouter>
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
    </HashRouter>
  );
}

export default App;
